import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isClientChild: 'auth/isClientChild',
      childEventDetail: 'event/childEventDetail'
    })
  },
  methods: {
    async getCompanies() {
      this.$store.dispatch('client/getClientList', {
        type: this.$route.name === 'EventSummaryCompany' ? 'parent' : 'children',
        limit: 9999,
        eventId: this.childEventDetail?.id,
        forHistory: this.$route.name === 'EventHistoryBuy' ? 1 : 0,
      });
    }
  },
  async mounted() {
    const loading = this.$loading.show();
    Promise.all([
      await this.$store.dispatch('event/getChildEventDetail'),
      this.getCompanies()
    ]).finally(() => {
      this.$loading.clear(loading);
    })
  },
}
